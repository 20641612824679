import React from 'react'
import WritePageSubmit from '../components/WritePageComponents/WritePageSubmit'

const WritePage = () => {
  return (
    <div>
      <WritePageSubmit />
    </div>
  )
}

export default WritePage
